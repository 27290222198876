<!--
 * @Editors: pan long
 -->
<template>
  <div>
    <Header title="sheshou"></Header>
    <BannerTitle title="sheshou"></BannerTitle>
    <div class="ssBox">
      <div class="OneIntr wow slideInUp">
        <ul>
          <li>
            <img src="@/assets/images/v2/ss-1.png" alt="">
            <b>{{ $t("ss.firSec.tips1") }}</b>
            <span>{{ $t("ss.firSec.desc1") }}</span>
          </li>
          <li>
            <img src="@/assets/images/v2/ss-2.png" alt="">
            <b>{{ $t("ss.firSec.tips2") }}</b>
            <span>{{ $t("ss.firSec.desc2") }}</span>
          </li>
          <li>
            <img src="@/assets/images/v2/ss-3.png" alt="">
            <b>{{ $t("ss.firSec.tips3") }}</b>
            <span>{{ $t("ss.firSec.desc3") }}</span>
          </li>
          <li>
            <img src="@/assets/images/v2/ss-4.png" alt="">
            <b>{{ $t("ss.firSec.tips4") }}</b>
            <span>{{ $t("ss.firSec.desc4") }}</span>
          </li>
          <li>
            <img src="@/assets/images/v2/ss-5.png" alt="">
            <b>{{ $t("ss.firSec.tips5") }}</b>
            <span>{{ $t("ss.firSec.desc5") }}</span>
          </li>
        </ul>
      </div>
      <div class="SecIntr wow slideInUp">
        <div class="adBox">
          <div class="adCont">
            <ul>
              <div class="adTit">{{ $t("ss.secSec.title") }}</div>
              <li @click="changeAd(0)" :class="idx == 0 ? 'otehr' : 'common'">
                <div>{{ $t("ss.secSec.tips0") }}</div>
                <p>{{ $t("ss.secSec.desc1") }}</p>
              </li>
              <li @click="changeAd(1)" :class="idx == 1 ? 'otehr' : 'common'">
                <div>{{ $t("ss.secSec.tips1") }}</div>
                <p>{{ $t("ss.secSec.desc2") }}</p>
              </li>
              <li @click="changeAd(2)" :class="idx == 2 ? 'otehr' : 'common'">
                <div>{{ $t("ss.secSec.tips2") }}</div>
                <p>{{ $t("ss.secSec.desc3") }}</p>
              </li>
              <li @click="changeAd(3)" :class="idx == 3 ? 'otehr' : 'common'">
                <div>{{ $t("ss.secSec.tips3") }}</div>
                <p>{{ $t("ss.secSec.desc4") }}</p>
              </li>
              <li @click="changeAd(4)" :class="idx ==4 ? 'otehr' : 'common'">
                <div>{{ $t("ss.secSec.tips4") }}</div>
                <p>{{ $t("ss.secSec.desc5") }}</p>
              </li>
              <li @click="changeAd(5)" :class="idx == 5 ? 'otehr' : 'common'">
                <div>{{ $t("ss.secSec.tips5") }}</div>
                <p>{{ $t("ss.secSec.desc6") }}</p>
              </li>
            </ul>
            <div class="imgPos">
              <img class="topPic1" :src="img[idx]" alt="" />
              <img class="topPic2" src="@/assets/images/v2/phone.png" alt="" />
              <img class="topPic3" src="@/assets/images/v2/shadow.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="ThiIntr wow slideInUp">
        <div class="thiBox">
          <div class="imgPos">
              <img class="topPic1" src="@/assets/images/v2/ss-11.png" alt="" />
              <img class="topPic2" src="@/assets/images/v2/phone.png" alt="" />
              <img class="topPic3" src="@/assets/images/v2/shadow.png" alt="" />
          </div>
          <div class="thiCont">
            <div>{{ $t("ss.secSec.title1") }}</div>
            <p>{{ $t("ss.secSec.tip") }}</p>
          </div>
        </div>
      </div>
      <div class="FourIntr wow slideInUp">
        <div class="innerBox">
          <div class="btoTit">{{ $t("ss.secSec.title3") }}</div>
          <ul class="innerUL">
            <li class="innerLI">
              <img class="innerImg" src="@/assets/images/v2/ss-12.png" alt="">
            </li>
          </ul>
        </div>
      </div>
      <div class="ForIntr wow slideInUp">
        <div class="btoTit">{{ $t("ss.secSec.title2") }}</div>
        <ul>
          <li>
            <img src="@/assets/images/v2/ss-6.png" alt="">
            <span>{{ $t("ss.secSec.tip0") }}</span>
          </li>
          <li>
            <img src="@/assets/images/v2/ss-7.png" alt="">
            <span>{{ $t("ss.secSec.tip1") }}</span>
          </li>
          <li>
            <img src="@/assets/images/v2/ss-8.png" alt="">
            <span>{{ $t("ss.secSec.tip2") }}</span>
          </li>
          <li>
            <img src="@/assets/images/v2/ss-9.png" alt="">
            <span>{{ $t("ss.secSec.tip3") }}</span>
          </li>
          <li>
            <img src="@/assets/images/v2/ss-10.png" alt="">
            <span>{{ $t("ss.secSec.tip4") }}</span>
          </li>
        </ul>
        <Start></Start>
      </div>
    </div>
    <Hotline></Hotline>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "../../components/header.vue";
import BannerTitle from "../../components/bannerTitle.vue";
import Footer from "../../components/footer.vue";
import Hotline from "../../components/hotline.vue";
import Start from "../../components/stars.vue";
import ad1 from "@/assets/images/v2/ad-ys.png";
import ad2 from "@/assets/images/v2/ad-bn.png";
import ad3 from "@/assets/images/v2/ad-kp.png";
import ad4 from "@/assets/images/v2/ad-cp.png";
import ad5 from "@/assets/images/v2/ad-jl.png";
import ad6 from "@/assets/images/v2/ad-spgg.png";

export default {
  name: "tianqin",
  components: {
    Header,
    BannerTitle,
    Footer,
    Hotline,
    Start
  },
  data () {
    return {
      idx: 0,
      img: [ad1, ad2, ad3, ad4, ad5, ad6]
    }
  },
  methods: {
    changeAd(type) {
      this.idx = type
    }
  }
};
</script>

<style lang="scss">
.ssBox {
  background: #fff;
  .OneIntr {
    width: 1300px;
    margin: auto;

    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;

      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        width: 430px;

        img {
          width:72px;
          height:72px;
        }

        b {
          width:320px;
          font-size:20px;
          font-family:PingFangSC-Semibold,PingFang SC;
          font-weight:600;
          color:rgba(51,51,51,1);
          line-height:28px;
          text-align: center;
          margin-top: 33px;
          margin-bottom: 13px;
        }

        span {
          width:290px;
          height:104px;
          font-size:14px;
          font-family:PingFangSC-Regular,PingFang SC;
          font-weight:400;
          color:rgba(153,153,153,1);
          line-height:26px;
          text-align: center;
        }
      }
      li:nth-child(4) {
        margin-left: 190px;
        margin-top:60px;
      }
      li:nth-child(5) {
        margin-right: 190px;
        margin-top:60px;
      }
    }
  }

  .SecIntr {
    background:#F3F6F9;

    .adBox {
      width: 900px;
      margin: auto;

      .adCont {
        display: flex;
        align-items: flex-start;

        ul {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 70px;

          .otehr {
            display: flex;
            flex-direction: column;
            justify-items: center;
            align-items: center;
            width:400px;
            height:120px;
            background:#0564CD;
            border-radius:8px;
            margin-bottom: 17px;
            transition-duration: 0.5s;

            div {
              height:28px;
              font-size:20px;
              font-family:PingFangSC-Regular,PingFang SC;
              font-weight:400;
              color: #fff;
              line-height:28px;
              margin-bottom: 12px;
              margin-top: 21px;
            }

            p {
              height:22px;
              padding: 0 10PX 20PX 25PX;
              font-size:16px;
              font-family:PingFangSC-Regular,PingFang SC;
              font-weight:400;
              color: #fff;
              line-height:22px;
            }
          }

          .common {
            display: flex;
            flex-direction: column;
            justify-items: center;
            align-items: center;
            width:400px;
            height:70px;
            background:#fff;
            border-radius:8px;
            margin-bottom: 17px;

            div {
              height:28px;
              font-size:20px;
              font-family:PingFangSC-Regular,PingFang SC;
              font-weight:400;
              color:rgba(34,34,34,1);
              line-height:28px;
              margin-bottom: 12px;
              margin-top: 21px;
            }

            p {
              height:22px;
              font-size:16px;
              font-family:PingFangSC-Regular,PingFang SC;
              font-weight:400;
              color:rgba(34,34,34,1);
              line-height:22px;
              display: none;
            }
          }

          .adTit {
            width:451px;
            height:56px;
            font-size:40px;
            font-family:PingFangSC-Semibold,PingFang SC;
            font-weight:600;
            color:rgba(51,51,51,1);
            line-height:56px;
            margin-top: 94px;
            margin-bottom: 38px;
            text-align: center;
          }
        }

        .imgPos {
          width:301px;
          height:627px;
          margin-left: 153px;
          margin-top: 184px;
          width: 301px;
          height: 627px;
          position: relative;

          .topPic1 {
            width: 282px;
            height: 600px;
            position: absolute;
            z-index: 10;
            left: 10px;
            top:14px;
          }
          .topPic2 {
            width:301px;
            height:627px;
            position: absolute;
            z-index:10;
          }
          .topPic3 {
            width: 407px;
            height: 775px;
            position: absolute;
            left: 11px;
            top: -2px;
          }
        }
      }
    }
  }

  .ThiIntr {
    width: 900px;
    margin: auto;

    .thiBox {
      display: flex;
      margin-top: 255px;

      .imgPos {
          width: 301px;
          height: 627px;
          position: relative;
           margin-right: 164px;

          .topPic1 {
            width: 282px;
            height: 606px;
            position: absolute;
            z-index: 10;
            left: 10px;
          }
          .topPic2 {
            width:301px;
            height:627px;
            position: absolute;
            z-index:10;
          }
          .topPic3 {
            width: 407px;
            height: 775px;
            position: absolute;
            left: 11px;
            top: -2px;
          }
        }

      .thiCont {
        height: 300px;
        margin-top:190px;

        div {
          font-size:40px;
          font-family:PingFangSC-Semibold,PingFang SC;
          font-weight:600;
          color:rgba(51,51,51,1);
          line-height:56px;
          margin-bottom: 24px;
        }

        p {
          width:420px;
          height:96px;
          font-size:16px;
          font-family:PingFangSC-Regular,PingFang SC;
          font-weight:400;
          color:rgba(153,153,153,1);
          line-height:32px;
        }
      }

    }
  }

  .FourIntr {
    background:#F3F6F9;
    .innerBox {
      width: 1300px;
      margin: auto;
      padding: 1px;
      .btoTit {
        height:56px;
        font-size:40px;
        font-family:PingFangSC-Semibold,PingFang SC;
        font-weight:600;
        color:rgba(51,51,51,1);
        text-align: center;
        margin-bottom: 47px;
        margin-top: 167px;
      }

      .innerUL {
        .innerLI {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-items: center;
          margin-bottom: 124px;

          .innerImg {
            width: 1300px;
            height:576px;
            border-radius:8px;
          }
        }
      }
    }
  }

  .ForIntr {
    width: 1300px;
    margin: auto;
    .btoTit {
      height:56px;
      font-size:40px;
      font-family:PingFangSC-Semibold,PingFang SC;
      font-weight:600;
      color:rgba(51,51,51,1);
      text-align: center;
      margin-bottom: 130px;
      margin-top: 130px;
    }

    ul {
      display: flex;
      align-items: center;
      margin-bottom: 190px;
      justify-content: space-between;

      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
          width:72px;
          height:72px;
          margin-bottom: 25px;
        }

        span {
          width:202px;
          height:28px;
          font-size:20px;
          font-family:PingFangSC-Regular,PingFang SC;
          font-weight:400;
          color:rgba(51,51,51,1);
          line-height:28px;
          text-align: center;
        }
      }
    }

  }
}
</style>
